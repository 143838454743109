import React from "react"

import Layout from "components/Layout"
import Seo from "components/Seo"
import Breadcrumbs from "components/Breadcrumbs"

import { Content } from "page_components/checkout_summary"

const Order = () => {
  return (
    <Layout>
      <Breadcrumbs title="Zamówienie" />
      <Content />
    </Layout>
  )
}

export const Head = () => <Seo title="Zamówienie" />

export default Order
